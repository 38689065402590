import React from "react";
import "./Features.css";
import feature from "../../components/contentFile/content.json"

const Features = () => {
  return (
    <section id="features" className="features">
    <div className="container" data-aos="fade-up">
      <header className="section-header">
        <h2>Features</h2>
        <p>{feature.feature_component.heading1}</p>
      </header>
      <div className="row">
        <div className="col-lg-6">
          <img
            src={feature.feature_component.img.src}
            className="img-fluid"
            alt={feature.feature_component.img.alt}
          />
        </div>
        <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
          <div className="row align-self-center gy-4">
            {feature.feature_component.feature_card1.map((item) => (
              <div
              key={item.id}
              className="col-md-6"
              data-aos="zoom-out"
              data-aos-delay={item.id + 1 * 100}
            >
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>{item.featureList}</h3>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row feture-tabs" data-aos="fade-up">
        <div className="col-lg-6">
          <h3>{feature.feature_component.heading2}</h3>
          <ul className="nav nav-pills mb-3">
            {feature.feature_component.feature_card2.map((item) => (
              <li key={item.id}>
                <a
                  className={`nav-link ${item.id === 1 ? 'active' : ''}`}
                  data-bs-toggle="pill"
                  href={`#tab${item.id}`}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <div className="tab-content">
            {feature.feature_component.feature_card3.map((item) => (
              <div key={item.id} className="tab-pane fade show active" id={`tab${item.id}`}>
              <p>
              {item.description}
              </p>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-6">
          <img
            src={feature.feature_component.img2.src}
            className="img-fluid"
            alt={feature.feature_component.img2.alt}
          />
        </div>
      </div>
      <div className="row feature-icons" data-aos="fade-up">
        <h3>{feature.feature_component.heading3}</h3>
        <div className="row">
          <div
            className="col-xl-4 text-center"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <img
              src="assets/img/features-3.png"
              className="img-fluid p-4"
              alt="feature_art"
            />
          </div>
          <div className="col-xl-8 d-flex content">
            <div className="row align-self-center gy-4">
              {feature.feature_component.feature_card4.map((item) => (
                <div key={item.id} className="col-md-6 icon-box" data-aos="fade-up">
                <i className={item.iconName} />
                <div>
                  <h4>{item.title}</h4>
                  <p>
                    {item.description}
                  </p>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default Features;
