import React from 'react'
import './NotFound.css'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

const NotFound = ({ error }) => {
  // const { t, i18n } = useTranslation("translation");

  // function handleClick(e) {

  //   console.log(e)
  //   i18n.changeLanguage(e);

  //   localStorage.setItem("lang", e);
  // }
  // useEffect(() => {
  //   let curruentlang = localStorage.getItem("lang")
  //   i18n.changeLanguage(curruentlang)
  // }, [])

  return (
    <section id="notfound">
      {/* <TransButton handler={(e)=>handleClick(e)}></TransButton> */}
      <div className="notfound">
        <div className="notfound-404">
          <div />
          <h1>404</h1>
        </div>
        <h2>Page not found</h2>
        <p>error</p>
        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
        <Link to='/'>Home page</Link>
      </div>
    </section>

  )
}

export default NotFound