import React, { useState, useEffect } from "react";
import "./Careers.css";
import Loading from "../../components/loading/Loading";
import NotFound from "../pagenotfound/NotFound";

import useScrollUp from "../../hook/useScrollUp";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import career from "../../components/contentFile/content.json"

const Careers = () => {
  useScrollUp();
  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(false)
  }, [])
  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      <Breadcrumbs page="Career With Us" pathname="Career-With-Us" />

      <section className="careers-container">
        <h1 className="heading">{career.career_page.heading}</h1>
        <p className="desc">{career.career_page.description}</p>

        <div className="row icon-boxes">
          {career.career_page.career_card.map((item) => (
            <div key={item.id} className="icon-box-container">
              <div className="icon-box">
                <div className="icon">
                  <i className={item.iconname} />
                </div>
                <h4 className="title">
                  <span>{item.title}</span>
                </h4>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Careers;
