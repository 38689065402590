import React, { useEffect, useState } from "react";
import "./Client.css";
import Aos from "aos";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import Loading from "../loading/Loading";
import client from "../../components/contentFile/content.json"

;

const Client = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const cachedData = sessionStorage.getItem("clientData");
  //       if (cachedData) {
  //         setData(JSON.parse(cachedData));
  //       } else {
  //         const response = await apiService.get(
  //           "/api/clients?populate=clientImage.image"
  //         );
  //         const newData = response.data?.data[0]
  //         sessionStorage.setItem('clientData', JSON.stringify(newData))
  //         setData(newData)
  //         console.log(newData)
  //       }
  //     } catch (error) {
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  // useEffect(() => {
  //   if (!loading && !error && data?.attributes?.isVisible) {
  //     const swiper = new Swiper(".clients-slider", {
  //       speed: 400,
  //       loop: true,
  //       autoplay: {
  //         delay: 5000,
  //         disableOnInteraction: false,
  //       },
  //       slidesPerView: "auto",
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //       breakpoints: {
  //         320: {
  //           slidesPerView: 2,
  //           spaceBetween: 40,
  //         },
  //         480: {
  //           slidesPerView: 3,
  //           spaceBetween: 60,
  //         },
  //         640: {
  //           slidesPerView: 4,
  //           spaceBetween: 80,
  //         },
  //         992: {
  //           slidesPerView: 6,
  //           spaceBetween: 120,
  //         },
  //       },
  //     });

  //     return () => {
  //       swiper.destroy();
  //     };
  //   }
  // }, [loading, error, data]);

  // if (loading) {
  //   return <Loading />;
  // }
  return (
    <>
      {/* {client.client_component.isVisible && !error && ( */}
        <section id="clients" className="clients">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>Our Clients</h2>
              <p>{client.client_component.heading}</p>
            </header>
            <div className="clients-slider swiper">
              <div className="swiper-wrapper align-items-center">
                {client.client_component.client_card.map((item) => (
                  <div key={item.id} className="swiper-slide">
                    <img
                      src={item.img_src}
                      className="img-fluid"
                      alt={item.img_alt}
                    />
                  </div>
                ))}
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </section>
      {/* )} */}
    </>
  );
};

export default Client;
