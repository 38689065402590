import React from "react";
import './Loading.css'
import { tailspin } from "ldrs";

const loading = () => {
  tailspin.register();

  return (
    // <div className="loading-container">
    //   <l-tailspin size="40" stroke="5" speed="0.9" color="#e11d47"></l-tailspin>
    // </div>
    <div className="main">
      <div className="loader"></div>
    </div>
  );
};

export default loading;
