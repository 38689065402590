import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumbs = ({page, pathname}) => {

  
  return (
    <section className="breadcrumbs">
        <div className="container">
          <ol>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>{pathname}</li>
          </ol>
          <h2>{page}</h2>
        </div>
      </section>
  )
}

export default Breadcrumbs