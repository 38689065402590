import React, { useEffect, useState } from "react";

const BackToTop = () => {
  const [isAcitve, setIsActive] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect (() => {
    const handleScroll = () => {
      setIsActive(window.scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  },[])
  return (
    <button
      onClick={scrollToTop}
      className={`back-to-top d-flex align-items-center justify-content-center ${isAcitve ? 'active' : ''}`}
    >
      <i className="bi bi-arrow-up-short"></i>
    </button>
  );
};

export default BackToTop;
