import React, { useEffect } from "react";

import "./Home.css";
import Aos from "aos";
import Loading from "../../components/loading/Loading";
import Features from "../../components/features/Features";
import Values from "../../components/values/Values";
import Testimonials from "../../components/testimonials/Testimonials";
import Client from "../../components/clients/Client";
import NotFound from "../pagenotfound/NotFound";
import useScrollUp from "../../hook/useScrollUp";
import { Link, useNavigate } from "react-router-dom";
import loading from "../../components/loading/Loading";
import data from "../../components/contentFile/content.json"
import { useState } from "react";
import Footer from "../../components/footer/Footer";

const Home = () => {

  const [isLoading, setIsLoading] = useState(true);
  let [error,seterror]=useState(true)
  useScrollUp();
  const navigate = useNavigate();
 

  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
    setIsLoading(false)
  }, []);

  if(isLoading){
    return <Loading/>
  }

  // if (error) {
  //   return <NotFound error={error} />;
  // }
  return (
    <>
      <section id="hero" className="hero d-flex align-items-center">
        
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">{data.home.hero_section.hero_title}</h1>
              <h2 data-aos="fade-up" data-aos-delay={400}>
                {data.home.hero_section.heroTagline}
              </h2>
              <div data-aos="fade-up" data-aos-delay={600}>
                <div className="text-center text-lg-start">
                  <button className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                    <span onClick={() => navigate(data.home.hero_section.btn_link)}>
                     {data.home.hero_section.btn}
                    </span>
                    <i className="bi bi-arrow-right" />
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img"
              data-aos="zoom-out"
              data-aos-delay={200}
            >
              <img
                src={data.home.hero_section.img.src}
                className="img-fluid"
                alt={data.home.hero_section.img.alt}
              />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        {/* ======= About Section ======= */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row gx-0">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="content">
                  <h3>{data.home.about_section.title}</h3>
                  <h2>{data.home.about_section.heading}</h2>
                  <p>{data.home.about_section.description}</p>
                  <div className="text-center text-lg-start">
                    <Link
                      to={data.home.about_section.btn_link}
                      className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span>{data.home.about_section.btn}</span>
                      <i className="bi bi-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="zoom-out"
                data-aos-delay={200}
              >
                <img
                  // src=""
                  src={data.home.about_section.img.src}
                  className="img-fluid"
                  alt={data.home.about_section.img.alt}
                />
              </div>
            </div>
          </div>
        </section>
        {/* End About Section */}
        {/* ======= Values Section ======= */}
        <Values />
        {/* End Values Section */}
        {/* <!-- ======= Features Section ======= --> */}
        <Features/>
        {/* <!-- End Features Section --> */}
        {/* ======= Testimonials Section ======= */}
        {(
          <Testimonials/>
        )}
        {/* End Testimonials Section */}
        {/* ======= Clients Section ======= */}
        {/* <Client /> */}
        {/* End Clients Section */}

      </main>
    </>
  );
};

export default Home;
