import React, { useEffect, useState } from "react";
import "./Contact.css";
import Aos from "aos";
import Loading from "../../components/loading/Loading";
import useScrollUp from "../../hook/useScrollUp";
import contact from "../../components/contentFile/content.json";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
} from "@mui/material";

const Contact = () => {
  useScrollUp();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
    setIsLoading(false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const response = await fetch("/api/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-recipient-email": `${process.env.REACT_APP_TO}`, // Set recipient dynamically
          "x-bcc-email": `${process.env.REACT_APP_BCC}`, // Set BCC dynamically
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsLoading(false);
        setOpenSuccess(true); // Open success dialog
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else {
        throw new Error("Failed to send message. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
      setOpenError(true);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Reach out</h2>
          <p>Business Inquiry</p>
        </header>
        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="row gy-4">
              {contact.contact_page.contact_card.map((item) => (
                <div key={item.id} className="col-md-6">
                  <div className="info-box">
                    <i className={item.iconName} />
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6">
            <form onSubmit={submitForm} className="php-email-form">
              <div className="row gy-4">
                <div className="col-md-6">
                  <TextField
                    name="name"
                    label="Your Name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    type="email"
                    name="email"
                    label="Your Email"
                    variant="outlined"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <TextField
                    name="subject"
                    label="Subject"
                    variant="outlined"
                    fullWidth
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <TextField
                    name="message"
                    label="Message"
                    variant="outlined"
                    multiline
                    rows={6}
                    fullWidth
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button type="submit" variant="contained" color="primary">
                    Send Message
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Dialog open={openSuccess} onClose={() => setOpenSuccess(false)}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>Your message has been sent. Thank you!</DialogContent>
      </Dialog>

      <Dialog open={openError} onClose={() => setOpenError(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>{error}</DialogContent>
      </Dialog>
    </section>
  );
};

export default Contact;
