import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";


const Header = () => {
  const ConsultancyRef = useRef(null);
  const DigitalInnovationRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [canClick, setCanClick] = useState(true);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeDeepDropdown, setActiveDeepDropdown] = useState(null);

  const handleMobileNavToggle = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const handleDropdownClick = (index) => {
    if (isMobileNavOpen) {
      setActiveDropdown(index === activeDropdown ? null : index);
    }
  };
  const handleDeepDropdownClick = (index) => {
    if (isMobileNavOpen) {
      setActiveDeepDropdown(index === activeDeepDropdown ? null : index);
    }
  };

  const handleClickEvent1 = () => {
    if (!canClick) {
      return;
    }

    ConsultancyRef.current.click();

    setTimeout(() => {
      ConsultancyRef.current.click();
      setCanClick(false);
    }, 300);
    setTimeout(() => setCanClick(true), 2000);

    navigate("/product-and-services#Consulting");
  };

  const handleClickEvent2 = () => {
    if (!canClick) {
      return;
    }

    DigitalInnovationRef.current.click();

    setTimeout(() => {
      DigitalInnovationRef.current.click();
      setCanClick(false);
    }, 300);
    setTimeout(() => setCanClick(true), 2000);

    navigate("/product-and-services#Digital-Innovation");
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setMobileNavOpen(false);
    setActiveDropdown(null);
    setActiveDeepDropdown(null);
  }, [location]);

  // const { t, i18n } = useTranslation("header");
 
  // function handleClick(e) {
   
  //   console.log(e)
  //   i18n.changeLanguage(e);

  //   localStorage.setItem("lang", e);
  // }
  // useEffect(() => {
  //   let curruentlang = localStorage.getItem("lang")
  //   i18n.changeLanguage(curruentlang)
  // }, [])
  return (
    <>
    
    <header
      id="header"
      className={`header fixed-top ${scrolled ? "header-scrolled" : ""}`}
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <Link to="/" className="logo d-flex align-items-center">
          <img src="/assets/img/logo.webp" alt="logo" />
        </Link>
        {/* <TransButton handler={(e)=>handleClick(e)} /> */}
        <nav
          id="navbar"
          className={`navbar ${isMobileNavOpen ? "navbar-mobile" : ""}`}
        >
          <ul>
            <li>
              <Link
                to="/"
                className={`nav-link scrollto ${location.pathname === "/" ? "active" : ""
                  }`}
              >
               Home
              </Link>
            </li>
            <li className="dropdown">
              <a
                className={`${location.pathname === "/product-and-services" ? "active" : ""
                  }`}
                onClick={() => handleDropdownClick(0)}
              >
                <span onClick={() => navigate("/product-and-services")}>
                  Product and Services
                </span>
                <i className="bi bi-chevron-down" />
              </a>
              <ul
                className={`dropdown ${activeDropdown === 0 ? "dropdown-active" : ""
                  }`}
              >
                <li className="dropdown">
                  <a onClick={() => handleDeepDropdownClick(1)}>
                    <span
                      onClick={() => {
                        navigate("/product-and-services#product");
                      }}
                    >
                      Products
                    </span>{" "}
                    <i className="bi bi-chevron-right" />
                  </a>
                  <ul
                    className={`dropdown ${activeDeepDropdown === 1 ? "dropdown-active" : ""
                      }`}
                  >
                    <li>
                      <a href="https://airpollutionapi.com/" target="_blank"> Air Pollution API</a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a onClick={() => handleDeepDropdownClick(0)}>
                    <span onClick={handleClickEvent1} ref={ConsultancyRef}>
                      Consulting
                    </span>
                    <i className="bi bi-chevron-right" />
                  </a>
                  <ul
                    className={`dropdown ${activeDeepDropdown === 0 ? "dropdown-active" : ""
                      }`}
                  >
                    <li>
                      <a href="/product-and-services#Consulting" onClick={handleClickEvent1}> product consulting</a>
                    </li>
                    <li>
                      <a href="/product-and-services#Consulting" onClick={handleClickEvent1}> devOps & cloud consulting</a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a onClick={() => handleDeepDropdownClick(2)}>
                    <span
                      onClick={handleClickEvent2}
                      ref={DigitalInnovationRef}
                    >
                    Digital Innovation
                    </span>
                    <i className="bi bi-chevron-right" />
                  </a>
                  <ul
                    className={`dropdown ${activeDeepDropdown === 2 ? "dropdown-active" : ""
                      }`}
                  >
                    <li>
                      <a href="/product-and-services#Digital-Innovation"  onClick={handleClickEvent2}>startUp development services</a>
                    </li>
                    <li>
                      <a href="/product-and-services#Digital-Innovation"  onClick={handleClickEvent2}> AI & datascience</a>
                    </li>
                    <li>
                      <a href="/product-and-services#Digital-Innovation"  onClick={handleClickEvent2}>Internet of things</a>
                    </li>
                    <li>
                      <a href="/product-and-services#Digital-Innovation"  onClick={handleClickEvent2}> devOps & cloud consulting</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/career-with-us"
                className={`nav-link scrollto ${location.pathname === "/career-with-us" ? "active" : ""
                  }`}
              >
                Career with us
              </Link>
            </li>
            <li className="mob-contact">
              <Link
                to="/business-inquiry"
                className="getstarted mob-contact-a scrollto"
              >
                business inquiry
              </Link>
             
            </li>


          </ul>
          <i
            className={`bi ${isMobileNavOpen ? "bi-x" : "bi-list"
              } mobile-nav-toggle`}
            onClick={handleMobileNavToggle}
          />
        </nav>
        <div className="btn-contact">
          <Link to="/business-inquiry" className="getstarted scrollto">
            business inquiry
          </Link>
        </div>
        


      </div>
    </header>
    </>
  );
};

export default Header;
