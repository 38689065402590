import React, {useState, useEffect} from "react";
import "./About.css";
import Loading from "../../components/loading/Loading";
import ReactMarkdown from "react-markdown";
import Client from "../../components/clients/Client";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import useScrollUp from "../../hook/useScrollUp";

const About = () => {
  useScrollUp();
  const [isLoading, setIsLoading] = useState(true);
 
  const [error, setError] = useState(null);

 useEffect(()=>{
  setIsLoading(false)
 },[])
 if(isLoading){
  return <Loading/>
 }

  return (
    <main id="main">
      <Breadcrumbs page={"About"} pathname={"About-Us"} />

      <section className="page">
        <div className="page-container">
          <h1 className="page-heading"></h1>
          <ReactMarkdown>Content Under Development: Stay Tuned for More!</ReactMarkdown>
        </div>
      </section>

      <Client />
    </main>
  );
};

export default About;
