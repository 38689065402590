import React, { useState, useEffect } from "react";
import "./Footer.css";
import footer from "../../components/contentFile/content.json";

import { Link } from "react-router-dom";

const Footer = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await apiService.get("/api/footers?populate=*");
  //       setData(response.data?.data[0]);
  //     } catch (error) {
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const currentYear = new Date();
  return (
    <>
      {/* {loading ||
        (!error && ( */}
      <footer id="footer" className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-5 col-md-12 footer-info">
                <Link to="/" className="logo d-flex align-items-center">
                  <img
                    src={footer.footer_component.logo_img.src}
                    alt={footer.footer_component.logo_img.alt}
                  />
                </Link>
                <div className="d-flex flex-wrap py-4 flags">
                  <div className="py-1 mr-5 w-25">
                    <a href="/">
                      <img
                        src={footer.footer_component.USA_logo.scr}
                        alt={footer.footer_component.IND_logo.alt}
                      />
                      <span>usa</span>
                    </a>
                  </div>
                  <div className="py-1 w-25">
                    <a href="/">
                      <img
                        src={footer.footer_component.IND_logo.scr}
                        alt={footer.footer_component.IND_logo.alt}
                      />
                      <span>india</span>
                    </a>
                  </div>
                </div>
                <p>{footer.footer_component.discription}</p>
                <div className="social-links mt-3">
                  {footer.footer_component.social_link.map((item) => (
                    <Link
                      key={item.id}
                      to={item.link}
                      target="blank"
                      className="twitter"
                    >
                      <i className={item.iconName} />
                    </Link>
                  ))}
                </div>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Important Links</h4>
                <ul>
                  {footer.footer_component.important_link.map((item) => (
                    <li key={item.id}>
                      <i className="bi bi-chevron-right" />
                      <Link to={item.link}>{item.Name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  {footer.footer_component.our_services.map((item) => (
                    <li key={item.id}>
                      <i className="bi bi-chevron-right" />
                      <Link to={item.link}>{item.Name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p className="footer-address">
                  {footer.footer_component.address
                    .split("_")
                    .map((line, index) => (
                      <p className="mb-2" key={index}>
                        {line}
                      </p>
                    ))}
                </p>
                <strong>Phone:</strong> {footer.footer_component.phone}
                <br />
                <strong>Email:</strong> {footer.footer_component.email}
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            © {currentYear.getFullYear()}{" "}
            <strong>
              <span>Bitspi</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
      {/* ))} */}
    </>
  );
};

export default Footer;
