import React, { useEffect, useRef, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import "./ServicePage.css";
import Aos from "aos";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import useScrollUp from "../../hook/useScrollUp";
import service from "../../components/contentFile/content.json"


const ServicePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  useScrollUp();
  const location = useLocation();
  const productRef = useRef();
  const [sectionRefs, setSectionRefs] = useState({});


  useEffect(() => {
    if (service?.service_page?.service_detail?.service_detail_content) {
      setSectionRefs((refs) =>
        service?.service_page?.service_detail?.service_detail_content.reduce((acc, item) => {
          acc[item.title.replace(/\s+/g, "-")] = React.createRef();
          return acc;
        }, {})
      );
    }
  }, []);

  useEffect(() => {
    switch (location.hash) {
      case "#product":
        productRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "#Consulting":
        scrollToSection("Consulting");
        break;
      case "#Digital-Innovation":
        scrollToSection("Digital-Innovation");
        break;
      default:
        break;
    }
  }, [location]);

  const scrollToSection = (section) => {
    if (sectionRefs[section]) {
      sectionRefs[section].current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
 
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
    setIsLoading(false)
  }, []);

  // const scrollToSection = (section) => {
  //   if (sectionRefs[section]) {
  //     sectionRefs[section].current.scrollIntoView({
  //       behavior: "smooth",
  //     });
  //   }
  // };
  if (isLoading) {
    return <Loading />;
  }

  // if (error) {
  //   return <NotFound error={error} />;
  // }

  return (
    <>
      <Breadcrumbs
        page={"Product & Services"}
        pathname={"Product-And-Services"}
      />

      <section
        className="section product-section"
        id="product"
        ref={productRef}
      >
        <div className="product-container" data-aos="fade-up">
          <header className="section-header">
            <h2>Products</h2>
          </header>
          <div className="site-blocks-cover overlay" data-aos="fade">
            <img src="assets/img/product-hero.png" alt="hero_banner" />
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-8 mt-lg-5 text-center">
                  <h1>{service.service_page.product_section.heroHeading}</h1>
                  <p className="mb-4">
                  {service.service_page.product_section.heroTagline}
                  </p>
                  <div>
                    <Link
                      to={service.service_page.product_section.btn_link}
                      target="blank"
                      className="mr-2 mb-2"
                    >
                      {service.service_page.product_section.btnText}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="section-intro pb-85px text-center">
            <h2 className="section-intro__title">
              {service.service_page.product_section.service_intro.title}
            </h2>
            <p className="section-intro__subtitle">
            {service.service_page.product_section.service_intro.description}
            </p>
          </div>
          <div className="container">
            <div className="row mt-4">
            {service.service_page.card.map((item) => (
                <div key={item.id} className="col-lg-4 mb-4">
                  <div className="card card-feature text-center text-lg-left mb-4 mb-lg-0">
                    <span className="card-feature__icon">
                      <i className={item.iconName}></i>
                    </span>
                    <h3 className="card-feature__title">{item.title}</h3>
                    <p className="card-feature__subtitle">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* service json */}

      <section className="section">
        <div className="container text-center" data-aos="fade-up">
          <header className="section-header">
            <h2>service</h2>
            <p>{service.service_page.service.service_card.heading}</p>
          </header>
          {/* row */}
          <div className="row d-flex justify-content-center">
            {/* map define */}
            {service.service_page.service.service_card.service_content.map((item) => (
              <div key={item.id} className="col-md-6 col-lg-3">
                <div className="service-card">
                  <div className="body">
                    <img
                      src={item.img_src}
                      alt={item.img_alt}
                      className="icon"
                    />
                    <h6 className="title">{item.title}</h6>
                    <p className="subtitle">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* end of row */}
        </div>
      </section>
      {/* service detail json  */}

      {service.service_page.service_detail.service_detail_content.map((item) => (
        <div
          key={item.id}
          className="services_wrapper"
          id={item.title.replace(/\s+/g, "-")}
          ref={sectionRefs[item.title.replace(/\s+/g, "-")]}
        >
          <section className="service_details">
            <div className="container">
              <header className="container__heading">
                <h2 className="title">{item.title}</h2>
                <p className="info">{item.description}</p>
              </header>
              <div className="card-container">
                {item?.cards?.map((service) => (
                  <Link key={service.id} className="card-link">
                    <div className="card">
                      <div className="card__body">
                        <div className="img-container">
                          <img
                            src={service.src}
                            alt={service.alt}
                          />
                        </div>
                        <div className="content">
                          <h3 className="title">{service.title}</h3>
                          <p className="info">{service.description}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </section>
        </div>
      ))}
    </>
  );
};

export default ServicePage;
