import React, { useEffect } from "react";
import "./Testimonials.css";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import testimonial from "../../components/contentFile/content.json"

const Testimonials = () => {
  useEffect(() => {
    new Swiper(".testimonials-slider", {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        
        clickable: true,
        
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
         
        },

        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        
      },
    });
  }, []);
  return (
    <section id="testimonials" className="testimonials">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Testimonials</h2>
          <p>{testimonial.testimonial_component.heading}</p>
        </header>
        <div
          className="testimonials-slider swiper"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="swiper-wrapper">
            {testimonial.testimonial_component.testimonial_detail.map((item) => (
              <div key={item.id} className="swiper-slide">
                <div className="testimonial-item">
                  <div className="stars">
                    {Array.from({ length: item?.ratings }, (_, index) => (
                      <i key={index} className="bi bi-star-fill" />
                    ))}
                  </div>
                  <p>{item.message}</p>
                  <div className="profile mt-auto">
                    {/* <img
                    src="assets/img/testimonials/testimonials-1.jpg"
                    className="testimonial-img"
                    alt='testimonials_sample'
                  /> */}
                    <h3>{item.name}</h3>
                    <h4>{item.role}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="swiper-pagination" />
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
