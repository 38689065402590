import React from "react";
import useScrollUp from "../../hook/useScrollUp";
import ReactMarkdown from "react-markdown";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

const TermServices = () => {
  useScrollUp();

  return (
    <>
      <Breadcrumbs pathname={"terms-and-services"} page={"Terms and services"} />
      <section className="page">
        <div className="page-container">
          <h1 className="page-heading">Term Services</h1>
          <ReactMarkdown>Content Under Development: Stay Tuned for More!</ReactMarkdown>
        </div>
      </section>
    </>
  );
};

export default TermServices;
