import "./index.css";
import { Helmet } from "react-helmet";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Loading from "./components/loading/Loading";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import NotFound from "./pages/pagenotfound/NotFound";
import About from "./pages/about/About";
import ServicePage from "./pages/service_page/ServicePage";
import BackToTop from "./components/backtotop/BackToTop";
import Careers from "./pages/careers/Careers";
import PrivayPolicy from "./pages/privacy policy/PrivayPolicy";
import TermServices from "./pages/term&services/TermServices";
import { useEffect, useState } from "react";

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [window]);

  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      <BrowserRouter>
        <BackToTop />
        <Header />
        <Helmet>
          <title>Bitspi - Sensing The World</title>
          <meta property="type" content="website" />
          <meta
            name="description"
            content="Bitspi is dedicated to help the professionals and manufacturers associated with pollution monitoring by developing advanced products using cutting edge technologies"
          />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/product-and-services" element={<ServicePage />} />
          <Route path="/career-with-us" element={<Careers />} />
          <Route path="/business-inquiry" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivayPolicy />} />
          <Route path="/terms-and-services" element={<TermServices />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
