import React from "react";
import useScrollUp from "../../hook/useScrollUp";
import ReactMarkdown from "react-markdown";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

const PrivayPolicy = () => {
  useScrollUp();
  return (
    <>
      <Breadcrumbs pathname={'privacy-policy'} page={'Privacy Policy'}/>
      <section className="page">
        <div className="page-container">
          <h1 className="page-heading"></h1>
          <ReactMarkdown>Content Under Development: Stay Tuned for More!</ReactMarkdown>
        </div>
      </section>
    </>
  );
};

export default PrivayPolicy;
