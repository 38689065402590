import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// import { I18nextProvider } from "react-i18next"
// import headerCHI from "./components/header/header.json"
// import notfoundCHI from "./pages/pagenotfound/NotFound.json"
// // import breadcrumbsCHI from "./components/breadcrumbs/Breadcrumbs.json"
// // import ClientCHI from "./components/clients/Client.json"
// // import featureCHI from "./components/features/feature.json"
// // import footerCHI from "./components/footer/footer.json"
// // import serviceCHI from "./components/services/Service.json"
// // import testimonialCHI from "./components/testimonials/Testimonials.json"
// // import valueCHI from "./components/values/Values.json"
// import i18next from "i18next";


// i18next.init({

//   resources: {

//     chi: {
//       header: headerCHI,
//       notfoundPage: notfoundCHI,
//       // client:ClientCHI,
//       // feature:featureCHI,
//       // value:valueCHI,
//       // service:serviceCHI,
//       // testimonial:testimonialCHI,
//       // footer:footerCHI,
//       // breadcrumb:breadcrumbsCHI
//     },


//   },

//   lng: "en", // if you're using a language detector, do not define the lng option



// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(


    <App />

  
);
